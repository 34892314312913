import React from 'react';
import HeroSection from '../../HeroSection';
import { homeObj1, homeObj2, homeObj3, homeObj4, homeObj5} from './Data';

function Code() {
  return (
    <>
      <HeroSection {...homeObj2} /> 
      <HeroSection {...homeObj1} />  
            
      
      
         
    </>
  );
}

export default Code;
