import React from 'react'
import './Footer.css'
import { Link } from 'react-router-dom'
import { RiGooglePlayFill, RiTelegramFill } from 'react-icons/ri'
import {
  IoLogoTiktok,
  IoLogoTumblr,
  IoLogoYoutube,
  IoLogoInstagram,
  IoLogoFacebook,
  IoLogoTwitter,
  IoLogoSnapchat,
  IoLogoPinterest,
  IoLogoTwitch,
  IoLogoDiscord,
} from 'react-icons/io5'

import { GoDeviceMobile } from 'react-icons/go'

function Footer() {
  return (
    <div className="footer-container">
      <section className="footer-subscription">
        <p className="footer-subscription-heading"></p>
        <p className="footer-subscription-text"></p>
        <div className="input-areas"></div>
      </section>
      {/* <div className='footer-links'>        
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>Videos</h2>
            <Link to={'//youtube.com/watch?v=_9Q_ERLaNdg'} target='_blank'>Nintendo App</Link>
          </div>
          <div className='footer-link-items'>
            <h2>Social Media</h2>
            <Link to={'//www.youtube.com/channel/UCYTyjHlofer0Btenc_GNeTw?view_as=subscriber'} target='_blank'>Youtube</Link>
            <Link to={'//www.instagram.com/'} target='_blank'>Instagram</Link>
            <Link to={'//www.facebook.com//'} target='_blank'>Facebook</Link>            
            <Link to={'//www.twitter.com/'} target='_blank'>Twitter</Link>
          </div>
        </div>
      </div> */}
      <section className="social-media">
        <div className="social-media-wrap">
          <div className="footer-logo">
            <Link to="/" className="social-logo">
              <GoDeviceMobile className="navbar-icon" color="#E50914" />
              Code Dev
            </Link>
          </div>
          <small className="website-rights">Code Dev © 2023</small>
          <div className="social-icons">
            <Link
              className="social-icon-link"
              to={'//www.youtube.com/@codedevone7002/videos'}
              target="_blank"
              aria-label="Youtube"
            >
              <IoLogoYoutube />
            </Link>
            <Link
              className="social-icon-link"
              to={'//www.instagram.com/codedev.one'}
              target="_blank"
              aria-label="Instagram"
            >
              <IoLogoInstagram />
            </Link>
            <Link
              className="social-icon-link"
              to={'//www.facebook.com/codedev.one/'}
              target="_blank"
              aria-label="Facebook"
            >
              <IoLogoFacebook />
            </Link>

            <Link
              className="social-icon-link"
              to={'//www.twitter.com/codedevone'}
              target="_blank"
              aria-label="Twitter"
            >
              <IoLogoTwitter />
            </Link>

            <Link
              className="social-icon-link"
              to={'//www.tumblr.com/code-dev-one'}
              target="_blank"
              aria-label="Tumblr"
            >
              <IoLogoTumblr />
            </Link>

            <Link
              className="social-icon-link"
              to={'//tiktok.com/@codedevone/'}
              target="_blank"
              aria-label="TikTok"
            >
              <IoLogoTiktok />
            </Link>

            <Link
              className="social-icon-link"
              to={'//t.snapchat.com/0xasldID'}
              target="_blank"
              aria-label="Snapchat"
            >
              <IoLogoSnapchat />
            </Link>

            {/* <Link
              className='social-icon-link'
              to={
                '//play.google.com/store/apps/details?id=z.q.app'
              }
              target='_blank'
              aria-label='GooglePlay'
            >
              <RiGooglePlayFill />
            </Link> */}

            <Link
              className="social-icon-link"
              to={'//pinterest.com/CodeDevOne/'}
              target="_blank"
              aria-label="Pinterest"
            >
              <IoLogoPinterest />
            </Link>

            {/* <Link
              className='social-icon-link'
              to={
                '//t.me/codedevone'
              }
              target='_blank'
              aria-label='Telegram'
            >
              <RiTelegramFill />
            </Link> */}

            <Link
              className="social-icon-link"
              to={'//twitch.tv/codedevone'}
              target="_blank"
              aria-label="Twitch"
            >
              <IoLogoTwitch />
            </Link>

            <Link
              className="social-icon-link"
              to={'//discord.gg/EbS6QkrvC9'}
              target="_blank"
              aria-label="Discord"
            >
              <IoLogoDiscord />
            </Link>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Footer
