export const homeObj20 = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Chemical Elements NFTS Collection',
  headline: '#20 Calcium',
  description:
    'The most abundant metal in the human body, calcium is also the fifth most common element on Earth.',
  buttonLabel: 'Buy Now!',
  imgStart: '',
  img: 'images/calcium.png',
  alt: '',
  media: 'px',
  alt: 'Vault',
  btn: 'calcium',
}

export const homeObj19 = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Chemical Elements NFTS Collection',
  headline: '#19 Potassium',
  description:
    'Potassium is vital for the human body, helping muscles and nerves work properly. For this, we rely on potassium-rich food, such as bananas, root vegetables, and avocados, which contain potassium chloride.',
  buttonLabel: 'Buy Now!',
  imgStart: '',
  img: 'images/potassium.png',
  alt: '',
  media: 'px',
  alt: 'Vault',
  btn: 'potassium',
}

export const homeObj18 = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Chemical Elements NFTS Collection',
  headline: '#18 Argon',
  description:
    'On Earth, hydrogen is commonly found in water. Although it is rare in Earth’s atmosphere, hydrogen is the most common element in the Universe. Stars, such as the Sun, contain large amounts of hydrogen.',
  buttonLabel: 'Buy Now!',
  imgStart: '',
  img: 'images/argon.png',
  alt: '',
  media: 'px',
  alt: 'Vault',
  btn: 'argon',
}

export const homeObj17 = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Chemical Elements NFTS Collection',
  headline: '#17 Chlorine',
  description:
    'Chlorine is named after the Greek word chlóros, which means “pale green”, a reference to the colour of this gaseous element. Chlorine is a highly reactive gas that forms a number of compounds, and does not exist pure in nature.',
  buttonLabel: 'Buy Now!',
  imgStart: '',
  img: 'images/chlorine.png',
  alt: '',
  media: 'px',
  alt: 'Vault',
  btn: 'chlorine',
}

export const homeObj16 = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Chemical Elements NFTS Collection',
  headline: '#16 Sulfur',
  description:
    'Known since ancient times, sulfur is one of the few non-metals that can be found pure in nature.',
  buttonLabel: 'Buy Now!',
  imgStart: '',
  img: 'images/sulfur.png',
  alt: '',
  media: 'px',
  alt: 'Vault',
  btn: 'sulfur',
}

export const homeObj15 = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Chemical Elements NFTS Collection',
  headline: '#15 Phosphorus',
  description:
    'Phosphorus was accidently discovered by German alchemist Hennig Brand. In 1669, in his quest for the mythical Philosopher’s Stone (a material some believed could turn any metal into gold), he boiled a large pot full of urine for days. This produced a mysterious glowing substance, which he called phosphorus, meaning “giver of light”. Phosphorus is the first element to have a discoverer with a recorded name. It is never pure in nature, and occurs in different minerals. Phosphorus has several flammable, solid forms, including red, white, black, and violet. The glow seen by Brand was caused by white phosphorus reacting with oxygen. Phosphorus is mainly found in phosphate minerals (in which phosphorus links to oxygen), such as apatite, its main ore. The strips on the sides of safety match boxes contain pure phosphorus.',
  buttonLabel: 'Buy Now!',
  imgStart: '',
  img: 'images/phosphorus.png',
  alt: '',
  media: 'px',
  alt: 'Vault',
  btn: 'phosphorus',
}

export const homeObj14 = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Chemical Elements NFTS Collection',
  headline: '#14 Silicon',
  description:
    'About 90 per cent of the minerals that make up Earth’s rocks contain silicon, a common element in our planet’s crust. Nearly all silicon minerals are compounds of silicon and oxygen, known as silicates. The most common silicate is quartz, the mineral form of silicon dioxide, or silica. It is also the most common substance in sand. Amethyst is a type of quartz. Quartz deposits are widely found in rocks such as granite and sandstone. A valuable type of silica is opal, which is used as a gemstone. The clays used to make pottery and ceramics are also silicates. Thin slices called silicon wafers drive electronic circuits.',
  buttonLabel: 'Buy Now!',
  imgStart: '',
  img: 'images/silicon.png',
  alt: '',
  media: 'px',
  alt: 'Vault',
  btn: 'silicon',
}

export const homeObj13 = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Chemical Elements NFTS Collection',
  headline: '#13 Aluminium',
  description:
    'Although aluminium is the most common metal in Earth’s rocks, scientists did not discover it until the early 1800s. Even then, it took a further 80 years for scientists to work out how to use the ore bauxite to extract large amounts of pure aluminium. It can also be found in other minerals, including variscite. Today, aluminium is often recycled because producing it anew requires 15 times more energy. The metal makes a strong, shiny foil when rolled flat, and is useful for storing foods. A fire protection suit made from this foil reflects away heat. Aluminium is the most widely used metal after iron. It is very lightweight compared to iron’s alloy steel and almost as strong. A dome made from aluminium, such as the one in the Esplanade Theatre in Singapore, can be much larger than a steel-based one, which would collapse under its own weight. Aluminium is also a good electrical conductor and so is used in overhead cables. Tough aluminium alloys are used to produce parts of some aircraft, including the Boeing 737.',
  buttonLabel: 'Buy Now!',
  imgStart: '',
  img: 'images/aluminium.png',
  alt: '',
  media: 'px',
  alt: 'Vault',
  btn: 'aluminium',
}

export const homeObj12 = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Chemical Elements NFTS Collection',
  headline: '#12 Magnesium',
  description:
    'This element largely exists deep inside Earth’s mantle, but it can also be found in seawater and many minerals in our planet’s crust, including serpentine. Another mineral, dolomite, is also a source of pure magnesium. Heating magnesia produces magnesium oxide, which is one of the ingredients in cement. Salts composed of magnesium, called Epsom salts, named after the place in England where they were first mined, work as a muscle relaxant. Magnesium silicate, known as talc, is a soft mineral used in body powders.',
  buttonLabel: 'Buy Now!',
  imgStart: '',
  img: 'images/magnesium.png',
  alt: '',
  media: 'px',
  alt: 'Vault',
  btn: 'magnesium',
}

export const homeObj11 = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Chemical Elements NFTS Collection',
  headline: '#11 Sodium',
  description:
    'Everyday salt contains lots of sodium. Although abundant on Earth, sodium is never found in its pure form naturally: it forms compounds with other elements. Sodium chloride, which also contains chlorine, is the most common sodium compound. It is also known as the mineral halite, and it is what makes seawater salty. Other sodium minerals include sodalite, a soft blue stone that can be shaped and polished. Pure sodium is soft enough to be cut with a knife. In ancient Egypt, crystals of sodium compounds were used to preserve dead bodies as mummies. Another useful compound is sodium bicarbonate, or baking soda, which makes dough rise by releasing bubbles of carbon dioxide.',
  buttonLabel: 'Buy Now!',
  imgStart: '',
  img: 'images/sodium.png',
  alt: '',
  media: 'px',
  alt: 'Vault',
  btn: 'sodium',
}

export const homeObj10 = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Chemical Elements NFTS Collection',
  headline: '#10 Neon',
  description:
    'Neon is a rare element: it makes up just 0.001 per cent of our atmosphere. Some of it was locked in Earth’s rocks when the planet formed, and this is released into the air by volcanic eruptions. Pure neon, a transparent gas, is extracted by cooling air to a temperature of −189.34°C (−308.81°F), at which point the neon gas in the air turns to liquid.',
  buttonLabel: 'Buy Now!',
  imgStart: '',
  img: 'images/neon.png',
  alt: '',
  media: 'px',
  alt: 'Vault',
  btn: 'neon',
}

export const homeObj9 = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Chemical Elements NFTS Collection',
  headline: '#9 Fluorine',
  description:
    'In the early 1800s, chemists in Europe realized that minerals such as fluorite contained an unknown element. However, it took another 70 years before the French chemist Henri Moissan extracted pure fluorine following a series of dangerous experiments that even poisoned him several times.',
  buttonLabel: 'Buy Now!',
  imgStart: '',
  img: 'images/fluorine.png',
  alt: '',
  media: 'px',
  alt: 'Vault',
  btn: 'fluorine',
}

export const homeObj8 = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Chemical Elements NFTS Collection',
  headline: '#8 Oxygen',
  description:
    'Oxygen is the most common element in Earth’s crust. Oxygen and its compounds make up half of all rocks and minerals on our planet. In the atmosphere, pure oxygen makes up around one-fifth of the air.',
  buttonLabel: 'Buy Now!',
  imgStart: '',
  img: 'images/oxygen.png',
  alt: '',
  media: 'px',
  alt: 'Vault',
  btn: 'oxygen',
}

export const homeObj7 = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Chemical Elements NFTS Collection',
  headline: '#7 Nitrogen',
  description:
    'Nitrogen surrounds us all the time because it is the transparent gas that makes up nearly hree-quarters of Earth’s atmosphere. Nitratine is one of the few minerals rich in nitrogen. The compound hydrazine is used in thrusters on spacecraft, such as the Phoenix Mars Lander.',
  buttonLabel: 'Buy Now!',
  imgStart: '',
  img: 'images/nitrogen.png',
  alt: '',
  media: 'px',
  alt: 'Vault',
  btn: 'nitrogen',
}

export const homeObj6 = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Chemical Elements NFTS Collection',
  headline: '#6 Carbon',
  description:
    'Carbon is the fourth most common element in the Universe. Each carbon atom can bond to four others, allowing them to form chains and rings. Pure carbon exists in three forms on Earth – graphite, diamond, and buckminsterfullerene (a structure based on 60 interlinked carbon atoms). Diamond is the hardest substance in nature.',
  buttonLabel: 'Buy Now!',
  imgStart: '',
  img: 'images/carbon.png',
  alt: '',
  media: 'px',
  alt: 'Vault',
  btn: 'carbon',
}

export const homeObj5 = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Chemical Elements NFTS Collection',
  headline: '#5 Boron',
  description:
    'This element is a very hard material and becomes even harder when made to react with carbon or nitrogen. Pure boron can be extracted from various minerals, including ulexite and kernite.',
  buttonLabel: 'Buy Now!',
  imgStart: '',
  img: 'images/boron.png',
  alt: '',
  media: 'px',
  alt: 'Vault',
  btn: 'boron',
}

export const homeObj4 = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Chemical Elements NFTS Collection',
  headline: '#4 Beryllium',
  description:
    'Beryllium was discovered in 1798 by the French chemist Louis Nicolas Vauquelin. He extracted the pure metal from emerald, which is a valuable green form of the mineral beryl.',
  buttonLabel: 'Buy Now!',
  imgStart: '',
  img: 'images/beryllium.png',
  alt: '',
  media: 'px',
  alt: 'Vault',
  btn: 'beryllium',
}

export const homeObj3 = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Chemical Elements NFTS Collection',
  headline: '#3 Lithium',
  description:
    'Lithium is the the lightest of all metals: in fact, it can easily float on water. Pure lithium is very reactive and exists in nature only in minerals, such as lepidolite and petalite.',
  buttonLabel: 'Buy Now!',
  imgStart: '',
  img: 'images/lithium.png',
  alt: '',
  media: 'px',
  alt: 'Vault',
  btn: 'lithium',
}

export const homeObj2 = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Chemical Elements NFTS Collection',
  headline: '#2 Helium',
  description:
    'This transparent gas was first discovered in 1868 by Sir William Ramsay, a Scottish chemist. Unlike hydrogen, which is very reactive, helium is a noble gas and does not react at all. This property makes it safe to use in objects such as party balloons and airships.',
  buttonLabel: 'Buy Now!',
  imgStart: '',
  img: 'images/helium.png',
  alt: '',
  media: 'px',
  alt: 'Vault',
  btn: 'helium',
}

export const homeObj1 = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Chemical Elements NFTS Collection',
  headline: '#1 Hydrogen',
  description:
    'Pure hydrogen is a transparent gas. The biggest planets, such as Jupiter, are vast balls of hydrogen mixed with other gases, such as helium and methane. On Earth, hydrogen is commonly found in water. Although it is rare in Earth’s atmosphere, hydrogen is the most common element in the Universe. Stars, such as the Sun, contain large amounts of hydrogen.',
  buttonLabel: 'Buy Now!',
  imgStart: '',
  img: 'images/hydrogen.png',
  alt: '',
  media: 'px',
  alt: 'Vault',
  btn: 'hydrogen',
}
