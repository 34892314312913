import React from 'react'
import './HeroSection.css'
import { Button } from './Button'
import { Link } from 'react-router-dom'
import ReactPlayer from 'react-player'

function HeroSection({
  lightBg,
  topLine,
  lightText,
  lightTextDesc,
  headline,
  description,
  buttonLabel,
  img,
  alt,
  imgStart,
  media,
  url,
  btn,
}) {
  return (
    <>
      <div
        className={lightBg ? 'home__hero-section' : 'home__hero-section darkBg'}
      >
        <div className="container">
          <div
            className="row home__hero-row"
            style={{
              display: 'flex',
              flexDirection: imgStart === 'start' ? 'row-reverse' : 'row',
            }}
          >
            <div className="col">
              <div className="home__hero-text-wrapper">
                <div className="top-line">{topLine}</div>
                <h1 className={lightText ? 'heading' : 'heading dark'}>
                  {headline}
                </h1>
                <p
                  className={
                    lightTextDesc
                      ? 'home__hero-subtitle'
                      : 'home__hero-subtitle dark'
                  }
                >
                  {description}
                </p>
                {
                  {
                    ap1: (
                      <Link to="/nintendo">
                        <Button buttonSize="btn--wide" buttonColor="blue">
                          {buttonLabel}
                        </Button>
                      </Link>
                    ),
                    code1: (
                      <Link to="/solve-expo-app-loading-error">
                        <Button buttonSize="btn--wide" buttonColor="blue">
                          {buttonLabel}
                        </Button>
                      </Link>
                    ),
                    code2: (
                      <Link to="/fontFamily-is-not-a-system-font-and-has-not-been-loaded-through-Font-loadAsync">
                        <Button buttonSize="btn--wide" buttonColor="blue">
                          {buttonLabel}
                        </Button>
                      </Link>
                    ),
                    hydrogen: (
                      <Link to="/hydrogen">
                        <Button buttonSize="btn--wide" buttonColor="blue">
                          {buttonLabel}
                        </Button>
                      </Link>
                    ),
                    helium: (
                      <Link to="/helium">
                        <Button buttonSize="btn--wide" buttonColor="blue">
                          {buttonLabel}
                        </Button>
                      </Link>
                    ),
                    lithium: (
                      <Link to="/lithium">
                        <Button buttonSize="btn--wide" buttonColor="blue">
                          {buttonLabel}
                        </Button>
                      </Link>
                    ),
                    beryllium: (
                      <Link to="/beryllium">
                        <Button buttonSize="btn--wide" buttonColor="blue">
                          {buttonLabel}
                        </Button>
                      </Link>
                    ),
                    boron: (
                      <Link to="/boron">
                        <Button buttonSize="btn--wide" buttonColor="blue">
                          {buttonLabel}
                        </Button>
                      </Link>
                    ),
                    carbon: (
                      <Link to="/carbon">
                        <Button buttonSize="btn--wide" buttonColor="blue">
                          {buttonLabel}
                        </Button>
                      </Link>
                    ),
                    nitrogen: (
                      <Link to="/nitrogen">
                        <Button buttonSize="btn--wide" buttonColor="blue">
                          {buttonLabel}
                        </Button>
                      </Link>
                    ),
                    oxygen: (
                      <Link to="/oxygen">
                        <Button buttonSize="btn--wide" buttonColor="blue">
                          {buttonLabel}
                        </Button>
                      </Link>
                    ),
                    fluorine: (
                      <Link to="/fluorine">
                        <Button buttonSize="btn--wide" buttonColor="blue">
                          {buttonLabel}
                        </Button>
                      </Link>
                    ),
                    neon: (
                      <Link to="/neon">
                        <Button buttonSize="btn--wide" buttonColor="blue">
                          {buttonLabel}
                        </Button>
                      </Link>
                    ),
                    sodium: (
                      <Link to="/sodium">
                        <Button buttonSize="btn--wide" buttonColor="blue">
                          {buttonLabel}
                        </Button>
                      </Link>
                    ),
                    magnesium: (
                      <Link to="/magnesium">
                        <Button buttonSize="btn--wide" buttonColor="blue">
                          {buttonLabel}
                        </Button>
                      </Link>
                    ),
                    aluminium: (
                      <Link to="/aluminium">
                        <Button buttonSize="btn--wide" buttonColor="blue">
                          {buttonLabel}
                        </Button>
                      </Link>
                    ),
                    silicon: (
                      <Link to="/silicon">
                        <Button buttonSize="btn--wide" buttonColor="blue">
                          {buttonLabel}
                        </Button>
                      </Link>
                    ),
                    phosphorus: (
                      <Link to="/phosphorus">
                        <Button buttonSize="btn--wide" buttonColor="blue">
                          {buttonLabel}
                        </Button>
                      </Link>
                    ),
                    sulfur: (
                      <Link to="/sulfur">
                        <Button buttonSize="btn--wide" buttonColor="blue">
                          {buttonLabel}
                        </Button>
                      </Link>
                    ),
                    chlorine: (
                      <Link to="/chlorine">
                        <Button buttonSize="btn--wide" buttonColor="blue">
                          {buttonLabel}
                        </Button>
                      </Link>
                    ),
                    argon: (
                      <Link to="/argon">
                        <Button buttonSize="btn--wide" buttonColor="blue">
                          {buttonLabel}
                        </Button>
                      </Link>
                    ),
                    potassium: (
                      <Link to="/potassium">
                        <Button buttonSize="btn--wide" buttonColor="blue">
                          {buttonLabel}
                        </Button>
                      </Link>
                    ),
                    calcium: (
                      <Link to="/calcium">
                        <Button buttonSize="btn--wide" buttonColor="blue">
                          {buttonLabel}
                        </Button>
                      </Link>
                    ),
                  }[btn]
                }
              </div>
            </div>
            <div className="col">
              <div className="home__hero-img-wrapper">
                {
                  {
                    px: <img src={img} alt={alt} className="home__hero-img" />,
                    video: (
                      <ReactPlayer
                        url={url}
                        controls="true"
                        className="home__hero-img"
                      />
                    ),
                  }[media]
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HeroSection
