export const homeObj2 = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'FONT.LOADASYNC ERROR SOLVED',
  headline: 'UPDATE YOUR DEPRECATED APP',
  description:
    'In this video I will solve the error: "fontFamily is not a system font and has not been loaded through Font.loadAsync"',
  buttonLabel: 'Watch on YouTube',
  imgStart: '',
  img: '',
  media: 'video',
  url: 'https://youtu.be/M6Ds2t__9Sg',
  alt: 'Vault',
  btn: 'code2',
}

export const homeObj1 = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'EXPO-APP-LOADING ERROR SOLVED',
  headline: 'UPDATE YOUR DEPRECATED APP',
  description:
    'In this video I will solve the error: "expo-app-loading is deprecated in favor of expo-splash-screen". Using the expo documentation I will implement in the code the "expo-splash-screen" instead of "expo-app-loading" which is deprecated.',
  buttonLabel: 'Watch on YouTube',
  imgStart: '',
  img: '',
  alt: '',
  media: 'video',
  url: 'https://youtu.be/Ti1vq0eVoeg',
  alt: 'Vault',
  btn: 'code1',
}

// export const homeObj0 = {
//   lightBg: false,
//   lightText: true,
//   lightTextDesc: true,
//   topLine: 'GOOGLE PLAY APP',
//   headline: 'ZQ APP',
//   description:
//     'Use this app for learning the atomic number of the elements.',
//   buttonLabel: 'Download',
//   imgStart: '',
//   img: 'images/px4.png',
//   media: 'px',
//   alt: 'Vault',
//   btn: 'ap4'
// };
