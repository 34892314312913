import React from 'react'
import HeroSection from '../../HeroSection'
import {
  homeObj1,
  homeObj2,
  homeObj3,
  homeObj4,
  homeObj5,
  homeObj6,
  homeObj7,
  homeObj8,
  homeObj9,
  homeObj10,
  homeObj11,
  homeObj12,
  homeObj13,
  homeObj14,
  homeObj15,
  homeObj16,
  homeObj17,
  homeObj18,
  homeObj19,
  homeObj20,
} from './Data'

function Nfts() {
  return (
    <>
      <HeroSection {...homeObj20} />
      <HeroSection {...homeObj19} />
      <HeroSection {...homeObj18} />
      <HeroSection {...homeObj17} />
      <HeroSection {...homeObj16} />
      <HeroSection {...homeObj15} />
      <HeroSection {...homeObj14} />
      <HeroSection {...homeObj13} />
      <HeroSection {...homeObj12} />
      <HeroSection {...homeObj11} />
      <HeroSection {...homeObj10} />
      <HeroSection {...homeObj9} />
      <HeroSection {...homeObj8} />
      <HeroSection {...homeObj7} />
      <HeroSection {...homeObj6} />
      <HeroSection {...homeObj5} />
      <HeroSection {...homeObj4} />
      <HeroSection {...homeObj3} />
      <HeroSection {...homeObj2} />
      <HeroSection {...homeObj1} />
    </>
  )
}

export default Nfts
