import React from 'react'
import { Redirect } from 'react-router-dom'
import './App.css'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Navbar from './components/Navbar'
import Code from './components/pages/Code/Code'
import Nfts from './components/pages/Nfts/Nfts'
import Footer from './components/pages/Footer.js/Footer'
import TaikoBook from './components/pages/Policies/TaikoBook'
import SpaceBNB from './components/pages/Policies/SpaceBNB'

function App() {
  return (
    <Router>
      <Navbar />
      <Switch>
        <Route path="/" exact component={Code} />
        <Route path="/Nfts" component={Nfts} />
        <Route path="/TaikoBook" component={TaikoBook} />
        <Route path="/SpaceBNB" component={SpaceBNB} />
        <Route
          exact
          path="/fontFamily-is-not-a-system-font-and-has-not-been-loaded-through-Font-loadAsync"
          component={() => {
            window.location.replace('https://youtu.be/M6Ds2t__9Sg')
          }}
        />
        <Route
          exact
          path="/solve-expo-app-loading-error"
          component={() => {
            window.location.replace('https://youtu.be/Ti1vq0eVoeg')
          }}
        />
        <Route
          exact
          path="/hydrogen"
          component={() => {
            window.location.replace(
              'https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/63520787449876140115776966798398054972568730779462961741253215349167844491265/'
            )
          }}
        />
        <Route
          exact
          path="/helium"
          component={() => {
            window.location.replace(
              'https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/63520787449876140115776966798398054972568730779462961741253215326078100307969/'
            )
          }}
        />
        <Route
          exact
          path="/lithium"
          component={() => {
            window.location.replace(
              'https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/63520787449876140115776966798398054972568730779462961741253215327177611935745/'
            )
          }}
        />
        <Route
          exact
          path="/beryllium"
          component={() => {
            window.location.replace(
              'https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/63520787449876140115776966798398054972568730779462961741253215328277123563521/'
            )
          }}
        />
        <Route
          exact
          path="/boron"
          component={() => {
            window.location.replace(
              'https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/63520787449876140115776966798398054972568730779462961741253215329376635191297/'
            )
          }}
        />
        <Route
          exact
          path="/carbon"
          component={() => {
            window.location.replace(
              'https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/63520787449876140115776966798398054972568730779462961741253215330476146819073/'
            )
          }}
        />
        <Route
          exact
          path="/nitrogen"
          component={() => {
            window.location.replace(
              'https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/63520787449876140115776966798398054972568730779462961741253215332675170074625/'
            )
          }}
        />
        <Route
          exact
          path="/oxygen"
          component={() => {
            window.location.replace(
              'https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/63520787449876140115776966798398054972568730779462961741253215333774681702401/'
            )
          }}
        />
        <Route
          exact
          path="/fluorine"
          component={() => {
            window.location.replace(
              'https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/63520787449876140115776966798398054972568730779462961741253215338172728213505/'
            )
          }}
        />
        <Route
          exact
          path="/neon"
          component={() => {
            window.location.replace(
              'https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/63520787449876140115776966798398054972568730779462961741253215339272239841281/'
            )
          }}
        />
        <Route
          exact
          path="/sodium"
          component={() => {
            window.location.replace(
              'https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/63520787449876140115776966798398054972568730779462961741253215340371751469057/'
            )
          }}
        />
        <Route
          exact
          path="/magnesium"
          component={() => {
            window.location.replace(
              'https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/63520787449876140115776966798398054972568730779462961741253215341471263096833/'
            )
          }}
        />
        <Route
          exact
          path="/aluminium"
          component={() => {
            window.location.replace(
              'https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/63520787449876140115776966798398054972568730779462961741253215342570774724609/'
            )
          }}
        />
        <Route
          exact
          path="/silicon"
          component={() => {
            window.location.replace(
              'https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/63520787449876140115776966798398054972568730779462961741253215343670286352385/'
            )
          }}
        />
        <Route
          exact
          path="/phosphorus"
          component={() => {
            window.location.replace(
              'https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/63520787449876140115776966798398054972568730779462961741253215344769797980161/'
            )
          }}
        />
        <Route
          exact
          path="/sulfur"
          component={() => {
            window.location.replace(
              'https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/63520787449876140115776966798398054972568730779462961741253215345869309607937'
            )
          }}
        />
        <Route
          exact
          path="/chlorine"
          component={() => {
            window.location.replace(
              'https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/63520787449876140115776966798398054972568730779462961741253215346968821235713/'
            )
          }}
        />
        <Route
          exact
          path="/argon"
          component={() => {
            window.location.replace(
              'https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/63520787449876140115776966798398054972568730779462961741253215348068332863489/'
            )
          }}
        />
        <Route
          exact
          path="/potassium"
          component={() => {
            window.location.replace(
              'https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/63520787449876140115776966798398054972568730779462961741253215350267356119041/'
            )
          }}
        />
        <Route
          exact
          path="/calcium"
          component={() => {
            window.location.replace(
              'https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/63520787449876140115776966798398054972568730779462961741253215351366867746817/'
            )
          }}
        />
      </Switch>
      <Footer />
    </Router>
  )
}
export default App
